import { mapHelper } from "@/utils/common.js";

//页面类型
const pagesType = [
  {
    value: 1,
    label: "小程序原生页面",
  },
  {
    value: 2,
    label: "h5页面",
  },
  {
    value: 3,
    label: "第三方h5页面",
  },
  {
    value: 4,
    label: "第三方小程序",
  },
];

const { map: pagesTypeMap, setOps: pagesTypeOps } = mapHelper.setMap(pagesType);

//入口类型
const inType = [
  {
    value: 1,
    label: "小程序",
  },
];
const { map: inTypeMap, setOps: inTypeOps } = mapHelper.setMap(inType);
//是否首页展示
const isShowHomeType = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 0,
    label: "否",
  },
];
const { map: isShowHomeTypeMap, setOps: isShowHomeTypeOps } =
  mapHelper.setMap(isShowHomeType);

//是否高频应用
const isShowPopulare = [
  {
    value: 1,
    label: "是",
  },
  {
    value: 0,
    label: "否",
  },
];
const { map: isShowPopulareMap, setOps: setIsShowPopulareOps } =
  mapHelper.setMap(isShowPopulare);

//对标省应用
const standardAppCodeList = [
  {
    value: 1,
    label: "社区活动",
  },
  {
    value: 2,
    label: "居民动态",
  },
  {
    value: 3,
    label: "社群圈子",
  },
  {
    value: 4,
    label: "我的社区",
  },
  {
    value: 5,
    label: "报事报修",
  },
  {
    value: 6,
    label: "事件处置",
  },
  {
    value: 7,
    label: "共治力量",
  },
  {
    value: 8,
    label: "民意征集",
  },
  {
    value: 9,
    label: "党员风采",
  },
  {
    value: 10,
    label: "社区满意度",
  },
  {
    value: 11,
    label: "信息公开",
  },
  {
    value: 12,
    label: "业主投票",
  },
  {
    value: 13,
    label: "智慧通行",
  },
  {
    value: 14,
    label: "声望  (积分)  系统",
  },
  {
    value: 15,
    label: "生活一张图",
  },
  {
    value: 16,
    label: "空间预约",
  },
  {
    value: 17,
    label: "空间市集预约",
  },
];
const { map: standardAppCodeListMap, setOps: standardAppCodeListOps } =
  mapHelper.setMap(standardAppCodeList);
export {
  standardAppCodeList,
  pagesType,
  pagesTypeMap,
  inType,
  inTypeMap,
  isShowHomeTypeMap,
  isShowHomeType,
  isShowPopulare,
  isShowPopulareMap,
  setIsShowPopulareOps,
};
