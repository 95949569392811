//获取租户/园区的所有系统应用模块列表【分页/不分页都支持】
const getTenantAppListUrl = `/gateway/blade-system/mng/communityModelSetting/listPaging`;
//新增/修改 租户/园区 的模块设置数据
const addTenantAppUrl = `/gateway/blade-system/mng/communityModelSetting/edit`;
//新增/修改 租户/园区 的模块设置数据
const tenantAppDetailUrl = `/gateway/blade-system/mng/communityModelSetting/detailById`;
//删除租户/园区的单个模块信息
const deleteTenantAppListUrl = `/gateway/blade-system/mng/communityModelSetting/delete`;
//获取所有埋点类型列表【不分页】
const getBuriedPointUrl = `/gateway/blade-system/mng/platformModelSetting/buriedPointTypeList`;
//园区 拷贝租户配置的模块设置数据（用于自定义配置的第一步生成基础配置数据）
const copyTenantAppListUrl = `/gateway/blade-system/mng/communityModelSetting/copyTenantModelSetting`;
//租户列表
const tenantListUrl = `/gateway/blade-system/tenant/select`;
//分类接口
const kindsListUrl = `/gateway/blade-system/mng/platformModelSetting/modelCategoryList`;
// 获取社区列表
const getDTenantList = `/gateway/blade-system/tenant/getDTenantListWithRight`;
export {
  getTenantAppListUrl,
  addTenantAppUrl,
  deleteTenantAppListUrl,
  copyTenantAppListUrl,
  tenantAppDetailUrl,
  getBuriedPointUrl,
  tenantListUrl,
  kindsListUrl,
  getDTenantList,
};
